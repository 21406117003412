/**
 *  TiQ Debug-Modus
 *  document.cookie="utagdb=true";
 *  document.cookie="tealium_site_section=ibe";
 */

import Backbone from 'backbone';
import _ from 'underscore';
import app from '../app/app';
import * as Constants from '../config/constants';
import {isOptOut} from '../util/opt-out';
import Url from '../util/url';


function loadTealium (tealiumEnv) {
    /* eslint-disable */
    if (!isOptOut()) {
        window.utag_cfg_ovrd = {noview: true};
        (function (a, b, c, d) {
            a = '//tags.tiqcdn.com/utag/tui-cruises/main/' + tealiumEnv + '/utag.js';
            b = document;
            c = 'script';
            d = b.createElement(c);
            d.src = a;
            d.type = 'text/java' + c;
            d.async = true;
            a = b.getElementsByTagName(c)[0];
            a.parentNode.insertBefore(d, a);
        })();
    }
    /* eslint-enable */
}

class TrackController {
    constructor () {
        _.extend(this, Backbone.Events);
    }

    init (trackingCodes) {
        this.trackingCodes = trackingCodes;
        this.optOutLetTrack = true;

        // TUICUNIT-583
        if (isOptOut()) {
            this.optOutLetTrack = false;
        }

        if (trackingCodes && document && document.getElementsByTagName) {
            if (this.optOutLetTrack) {
                // TUICIBE-528: A/B Testing Tracking, this will be set per grunt Task
                if (window.useDimensionValue) {
                    this.dimension18 = window[window.useDimensionValue];
                }

                // TUICUNIT-793: Tealium
                if (!window.utag) {
                    if (trackingCodes.tealium && trackingCodes.tealium.env !== 'dev') {
                        loadTealium(trackingCodes.tealium.env);
                    }
                }
            }
        }
    }

    // TUICUNIT-278
    setInAppTracking () {
        this.dimension20 = 'Native App';
    }

    // TUICUNIT-2311
    setRecoveryTracking (params) {
        this.recoveryUtmCampaign = params[Constants.CLOUDIQ_RECOVERY_UTM_CAMPAIGN] || null;
        this.recoveryUtmContent = params[Constants.CLOUDIQ_RECOVERY_UTM_CONTENT] || null;
        this.recoveryUtmId = params[Constants.CLOUDIQ_RECOVERY_UTM_ID] || null;
        this.recoveryUtmMedium = params[Constants.CLOUDIQ_RECOVERY_UTM_MEDIUM] || null;
        this.recoveryUtmSource = params[Constants.CLOUDIQ_RECOVERY_UTM_SOURCE] || null;
        this.recoveryUtmTerm = params[Constants.CLOUDIQ_RECOVERY_UTM_TERM] || null;
    }

    trackParamter () {
        const cabin = app.apiSession.bookingStatus.get('cabin') || [];
        let cabinCount = 1;
        let cabinCategoryTypeNames = '';
        let cabinCategoryTypeCodes = '';
        let cabinPriceModels = '';
        let cabinNumbers = '';
        let deckNumberAndNames = '';
        let cabinCategoryNames = '';
        let ga4CabinNumbers = '';
        let ga4deckNumberAndNames = '';
        let ga4CabinPrices = '';
        if (Array.isArray(cabin)) {
            cabinCount = cabin.length;
            cabin.forEach((item, key) => {
                // TUICUNIT-3084
                const maybeHasVIP = `${item.vip ? ` ${item.vip}` : ''}`;
                let seperator = '';
                if (key !== 0) {
                    seperator = '|';
                }
                cabinCategoryTypeNames = `${cabinCategoryTypeNames}${seperator}${item.cabinCategoryTypeName}`;
                cabinCategoryTypeCodes = `${cabinCategoryTypeCodes}${seperator}${item.cabinCategoryTypeCode}`;
                cabinPriceModels = `${cabinPriceModels}${seperator}${item.priceModel}${maybeHasVIP}`;
                cabinNumbers = `${cabinNumbers}${seperator}${item.cabinNumber ? item.cabinNumber : '-'}`;
                deckNumberAndNames = `${deckNumberAndNames}${seperator}${item.deckNumber ? item.deckNumber : '-'}`;
                let detailName = '-';
                if (item.details) {
                    detailName = (item.details.name) ? (item.details.name) : '-';
                }
                cabinCategoryNames = `${cabinCategoryNames}${seperator}${detailName}`;
                // TUICUNIT-3138 ga4 addOns
                const deckName = (item.deckName) ? `-${item.deckName}` : '';
                ga4deckNumberAndNames = `${ga4deckNumberAndNames}${seperator}${item.deckNumber ? `${item.deckNumber}${deckName}` : 'open'}`;
                ga4CabinNumbers = `${ga4CabinNumbers}${seperator}${item.cabinNumber ? item.cabinNumber : 'open'}`;
                ga4CabinPrices = `${ga4CabinPrices}${seperator}${item.totalPricepPerCabin ? this.ga4FormatPrice(item.totalPricepPerCabin) : '0.00'}`;
            });
        }

        const paymentTypeApi = app.apiSession.bookingStatus.get('payment') || {};
        const totalBookingPrice = app.apiSession.bookingStatus.get('totalBookingPrice') || 0;
        const contentTripInfo = app.contentApi.tripInfo || {};
        const initParams = app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER);
        const itinerary = contentTripInfo.get('itinerary') || [];

        return {
            isB2B: app.apiSession.bookingStatus.agency.isB2B(),
            tripName: contentTripInfo.get('tripName'),
            tripCode: app.apiSession.bookingStatus.trip.get('auftragsnummer'),
            itineraryStart: itinerary[0] || {},
            itineraryEnd: itinerary[itinerary.length - 1] || {},
            ePackageCode: initParams.ePackageCode,
            tripDateFrom: contentTripInfo.get('dateFrom'),
            tripDateTo: contentTripInfo.get('dateTo'),
            tripDateDuration: contentTripInfo.get('numberOfNights'),
            customvar6: contentTripInfo.get('customvar6') || '',
            cabinCount: cabinCount,
            cabinCategoryTypeName: cabinCategoryTypeNames,
            cabinCategoryTypeCode: cabinCategoryTypeCodes,
            cabinPriceModel: cabinPriceModels,
            availablePriceModels: this.findAllavailablePriceModels(cabinCount),
            ship: app.contentApi.tripInfo.get('ship'),
            shipCode: app.apiSession.bookingStatus.trip.get('shipCode'),
            adultCount: app.apiSession.bookingStatus.party.get('adultCount'),
            childCount: app.apiSession.bookingStatus.party.get('childCount'),
            cabinCategoryName: cabinCategoryNames,
            cabinNumber: cabinNumbers,
            deckNumberAndName: deckNumberAndNames,
            shipBoundTransport: app.apiSession.bookingStatus.shipBoundTransport.get('type') || '',
            shipBoundOrigin: app.apiSession.bookingStatus.shipBoundTransport.get('origin') || '',
            homeBoundTransport: app.apiSession.bookingStatus.homeBoundTransport.get('type') || '',
            homeBoundDestination: app.apiSession.bookingStatus.homeBoundTransport.get('destination') || '',
            insurances: app.apiSession.bookingStatus.insurances.toJSON(),
            participantPrices: app.apiSession.bookingStatus.participantPrices.toJSON(),
            paymentTypeView: app.ibeController.ibeStorage.get('selectedPaymentMethod') ? app.ibeController.ibeStorage.get('selectedPaymentMethod').type : null,
            paymentTypeApi: paymentTypeApi.paymentType,
            dimension18: this.dimension18 || '',
            dimension20: this.dimension20 || '',
            utmCampaign: this.recoveryUtmCampaign || '',
            utmContent: this.recoveryUtmContent || '',
            utmId: this.recoveryUtmId || '',
            utmMedium: this.recoveryUtmMedium || '',
            utmSource: this.recoveryUtmSource || '',
            utmTerm: this.recoveryUtmTerm || '', // some GA4 parameter
            ecShipBrand: 'mein-schiff',
            ecPassengers: `${app.apiSession.bookingStatus.party.get('adultCount')};${app.apiSession.bookingStatus.party.get('childCount')}`,
            itemVariant: `${this.ga4formatDate(contentTripInfo.get('dateFrom'))}-${this.ga4formatDate(contentTripInfo.get('dateTo'))}`,
            ecStartDate: this.ga4formatDate(contentTripInfo.get('dateFrom')),
            ecEndDate: this.ga4formatDate(contentTripInfo.get('dateTo')),
            agencyNumber: app.apiSession.bookingStatus.agency.getNumber(),
            totalPrice: this.ga4FormatPrice(totalBookingPrice),
            ecCabinNumber: ga4CabinNumbers,
            ecCabinDeck: ga4deckNumberAndNames,
            ecCabinPrice: ga4CabinPrices,
            ecCampainCodes: app.apiSession.bookingStatus.campaignCodes.getAllCodesOnly()
        };
    }

    /* TUICUNIT-3138: Tealium GA4 Addon */
    ga4DefaultParameter () {
        const query = app.ibeController.ibeStorage.get(Constants.ALL_QUERY_PARAMETER);
        const trackParamter = this.trackParamter();
        return ({
            'page_platform': 'ibe',
            'content_group': '',
            'page_country': 'de',
            'page_category': 'checkout',
            'page_url_parameter': this.ga4formatString(query),
            'page_login_status': 'false',
            'page_test_variant': 'original',
            'page_native_app': trackParamter.dimension20 ? 'true' : 'false',
            'page_b2b': this.tealiumDataLayerIsB2B(),
            'page_region_code': this.ga4formatString(this.mainRegionsCode()),
            'page_route_code': '',
            'page_route_recommendations': '',
            'page_language': this.getLocale()
        });
    }

    mainRegions () {
        const regions = app.contentApi.tripInfo.get('region') || [];
        const result = [];
        if (regions.length) {
            regions.forEach((v) => {
                result.push(v.regionName);
            });
        }
        return result.join(',');
    }

    mainRegionsCode () {
        const regions = app.contentApi.tripInfo.get('region') || [];
        const result = [];
        if (regions.length) {
            regions.forEach((v) => {
                result.push(v.regionCode);
            });
        }
        return result.join(',');
    }

    /* TUICUNIT-3138: Tealium GA4 Addon */
    ga4formatString (value) {
        value = String(value);
        value = value.toLowerCase().split(' ').join('-');
        return value;
    }

    /* TUICUNIT-3138: Tealium GA4 Addon */
    ga4FormatPrice (value) {
        return Number.parseFloat(value).toFixed(2);
    }

    /* TUICUNIT-3138: Tealium GA4 Addon */
    ga4formatDate (value) {
        value = String(value);
        value = value.split('-').join('');
        return value;
    }

    /**
     * TUICUNIT-3138: Tealium GA4 Addon
     *
     * value is separated via | like ("innenkabine|außenkabine")
     * returns a format like ({cabinCount}-{valuePart1},{valuePart2})
     * @param {number} cabinCount
     * @param {string} value
     * @returns string like this "2-innenkabine,außenkabine"
     */
    ga4formatMultiCabin (cabinCount, value) {
        return `${cabinCount}-${this.ga4formatString(value).split('|').join(',')}`;
    }

    findAllavailablePriceModels (cabincount) {
        const apiSteps = (app.apiSession) ? app.apiSession.steps : null;
        let result = '';
        if (apiSteps) {
            for (let i = 1; i <= cabincount; i++) {
                let resulttmp = '';
                const stepModel = apiSteps.get(`cabinType/cabin-${i}`);
                const thisInput = stepModel ? stepModel.inputs.get(Constants.NETMATCH_INPUT_CABINTYPE_CABINTYPE) : null;
                const thisInputOptions = thisInput ? thisInput.get('options') : [];

                // groupBy model
                const gruppByCabinsByModel = thisInputOptions
                    ? thisInputOptions.reduce((list, raw) => {
                        if (raw.isBlocked) {
                            return list;
                        }
                        const maybeHasVIP = `${raw.model}${raw.vipTariff ? raw.vipTariff : ''}`;
                        list[maybeHasVIP] = (list[maybeHasVIP] || []).concat(raw);
                        return list;
                    }, {})
                    : {};
                let seperator = '';
                if (i !== 1) {
                    seperator = '|';
                }
                Object.keys(gruppByCabinsByModel).forEach((key) => {
                    resulttmp = `${resulttmp}_${key}`;
                });
                resulttmp = resulttmp.substring(1);
                result = `${result}${seperator}${resulttmp}`;
            }

        }
        return result;
    }

    pageView (bookingPageModel, apiStep = null) {
        let pageTitle = null;
        let route = null;
        if (bookingPageModel) {
            const pathDetail = bookingPageModel.get('tracking');
            route = bookingPageModel.get('route');
            if (pathDetail && pathDetail.pageTitle) {
                pageTitle = pathDetail.pageTitle;
            }
            if (apiStep !== null) {
                if (pathDetail && pathDetail.pageTitleApiStep[apiStep]) {
                    pageTitle = pathDetail.pageTitleApiStep[apiStep];
                }
            }
        }
        if (route && pageTitle) {
            if (route !== 'confirm') {
                if (this.optOutLetTrack) {
                    this.tealiumTracking(route, pageTitle);
                }
            }
        }
    }

    eventTracking (values = {}) {
        try {
            if (this.optOutLetTrack) {
                this.tealiumEvent(values);
                // trigger tealiumTracking in case of error-page
                if (values.error) {
                    if (values.action === 'IBE - Other') {
                        return true;
                    }
                    this.logForTUIC(values);
                    this.tealiumTracking('Error', '', values);
                }
            }
        } catch (e) {
            // console.warn('Event tracking failed:', e);
        }
    }

    /* TUICUNIT-3138: Tealium GA4 Addon */
    ga4EcommerceTracking (type, values = {}) {
        let data = {};
        switch (type) {
            case 'begin':
                data = this.ga4TealiumEcommerceBegin();
                break;
            case 'view':
                data = this.ga4TealiumEcommerceView();
                break;
            case Constants.TRACK_PAYMENT_ADD_INFO:
                data = this.ga4TealiumEcommerceAddPaymentInfo();
                break;
            case Constants.TRACK_ADD_TO_CART:
                data = this.ga4TealiumEcommerceAddToCart();
                break;
            case 'purchase':
                data = this.ga4TealiumEcommercePurchase(values);
                break;
        }
        return data;
    }

    /**
     * TUICUNIT-2904, TUICUNIT-3012
     * a new additional stupid TUIC log script
     */
    async logForTUIC (values) {
        try {
            const env = this.trackingCodes.tealium.env || '';
            const initParams = app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER) || {};
            let sessionID = app.ibeController.ibeStorage.get(Constants.NETMATCH_API_SESSION_ID_KEY) || '';
            let errorURL = app.ibeController.ibeStatus.apiUrl || '';

            if (sessionID === '' && app.ibeController.ibeStatus.mainErrorSessionId) {
                sessionID = app.ibeController.ibeStatus.mainErrorSessionId;
            }


            let contentProxyRoot = Constants.CONTENT_PROXY_ROOT_URL_TEST;
            let ibeProxyRoot = Constants.IBE_PROXY_ROOT_URL_TEST;
            if (env === 'prod') {
                contentProxyRoot = Constants.CONTENT_PROXY_ROOT_URL;
                ibeProxyRoot = Constants.IBE_PROXY_ROOT_URL;
            }
            errorURL = errorURL.replace('/api/content/', contentProxyRoot);
            errorURL = errorURL.replace('/api/ibe/', ibeProxyRoot);
            errorURL = errorURL.replace('{apiSession}', sessionID);

            let description = '';
            if (values.action === 'IBE - DataMissmatch') {
                description = '{..., "trip": {}} Not Exist';
            }
            if (values.action === 'IBE - ContentMiddleware' && app.ibeController.ibeStatus.httpStatus === '200') {
                description = 'invalid response';
            }

            if (values.action === 'IBE - NO INIT PARAMETER') {
                errorURL = '';
                app.ibeController.ibeStatus.httpStatus = null;
            }
            if (values.action === 'IBE - IBEAPI' && app.ibeController.ibeStatus.httpStatus === '503') {
                values.action = 'IBE – IBEAPI – front-end timeout exceeded';
            }

            const toLog = {
                env: env.replace('qa', 'test'),
                error: values.action,
                label: values.label,
                tripCode: initParams.tripCode || '',
                ePackageCode: initParams.ePackageCode || '',
                apiHttpStatus: app.ibeController.ibeStatus.httpStatus ? `${app.ibeController.ibeStatus.httpStatus} (${app.ibeController.ibeStatus.httpStatusText})` : '',
                apiUrl: errorURL,
                description: description

            };
            console.log((JSON.stringify(toLog)));

            if (window.utag?.gdpr?.getConsentState() === 1) {
                fetch('/tuic-log/log.php', {
                    method: 'POST',
                    body: JSON.stringify(toLog)
                }).then(() => {
                    // console.log(response);
                });
            }

        } catch (e) {
            // console.warn('Event tracking failed:', e);
        }
        return true;
    }

    confirmTracking (values) {
        const route = 'confirm';
        const pageTitle = app.bookingPages.get(route).get('tracking').pageTitle;

        if (this.optOutLetTrack) {
            this.tealiumTracking(route, pageTitle, values);
        }
    }

    /**
     *   Tealium Tag - TUICUNIT-297
     *
     *  TiQ Debug-Modus
     *  document.cookie="utagdb=true";
     *  document.cookie="tealium_site_section=ibe";
     */
    tealiumTracking (route, pageTitle, values = null) {
        let result = '';
        let ga4Ecommerce = {};
        switch (route) {
            case Constants.PAGE_CABIN:
                result = this.tealiumTrackingCabin(pageTitle);
                ga4Ecommerce = this.ga4EcommerceTracking(Constants.TRACK_ADD_TO_CART);
                break;
            case Constants.PAGE_TRAVEL:
                result = this.tealiumTrackingTransport(pageTitle);
                break;
            case Constants.PAGE_PERSONAL:
                result = this.tealiumTrackingPersonal(pageTitle);
                break;
            case Constants.PAGE_INSURANCE:
                result = this.tealiumTrackingInsurance(pageTitle);
                ga4Ecommerce = this.ga4EcommerceTracking('begin');
                break;
            case Constants.PAGE_OVERVIEW:
                result = this.tealiumTrackingOverview(pageTitle);
                ga4Ecommerce = this.ga4EcommerceTracking('view');
                break;
            case Constants.PAGE_PAYMENT:
                result = this.tealiumTrackingPayment(pageTitle);
                break;
            case Constants.PAGE_CONFIRM:
                result = this.tealiumTrackingConfirm(pageTitle, values);
                ga4Ecommerce = this.ga4EcommerceTracking('purchase', values);
                break;
            case 'Error':
                result = this.tealiumTrackingError(values);
                break;
            default:
                break;
        }
        console.info('%c tealium Page Tracking ', 'background: #222; color: #ffff00', result);
        console.info('%c GA4 E-Commerce -> tealium Page Tracking ', 'background: #222; color: #ffff00', ga4Ecommerce);

        /* eslint-disable */
        if (window.utag && result !== '') {
            window.utag.view(result);
            if (Object.keys(ga4Ecommerce).length !== 0) {
                window.utag.link(ga4Ecommerce);
            }
        }
        /* eslint-enable */
    }

    ga4Tracking (type) {
        let ga4Ecommerce = {};

        switch (type) {
            case Constants.TRACK_PAYMENT_ADD_INFO: {
                ga4Ecommerce = this.ga4EcommerceTracking(Constants.TRACK_PAYMENT_ADD_INFO);
                break;
            }
            default:
                break;
        }

        console.info('%c GA4 E-Commerce -> tealium ga Tracking ', 'background: #222; color: #ffff00', ga4Ecommerce);

        if (window.utag) {
            if (Object.keys(ga4Ecommerce).length !== 0) {
                window.utag.link(ga4Ecommerce);
            }
        }
    }

    tealiumEvent (params = {}) {
        const defaultParams = {
            eventCategory: 'IBE'
        };
        let eventCategory = defaultParams.eventCategory;
        if (params.key) {
            params.label = this.tealiumEventLabel(params, params.pageView);
            if (params.key === Constants.PAGE_CABIN) {
                params.action = 'Kabinenwahl_CTA';
            }
        }
        if (params.error) {
            const errorParam = this.tealiumEventErrorParams(params.error);
            eventCategory = errorParam.category;
            params.action = errorParam.action;

            params.ga4eventName = 'error';
            params.ga4eventRequest = window.location.href;
            params.ga4eventDisplayedMessage = params.ga4eventType === '404' ? 'seite-nicht-gefunden' : 'ihre-reise-ist-im-urlaub';
            // console.log(params);
        }

        // [AREA].[ELEMENT].[CATEGORY].[LABEL]
        // content.button.kabine-auswaehlen
        if (params.ga4clickLabel) {
            params.ga4clickLabel = this.ga4formatString(params.ga4clickLabel);
        }
        if (params.ga4eventName) {
            console.info(`%c GA4 -> tealiumEvent -> eventName: ${params.ga4eventName}`, 'background: #222; color: #ffff00');
        }
        if (params.action) {
            console.info(`%c tealiumEvent -> Category: ${eventCategory} | Action: ${params.action} | Label: ${params.label}`, 'background: #222; color: #ffff00');
        }

        if (params.ga4clickLabel) {
            console.info(`%c GA4 -> tealiumEvent -> clickLabel: ${params.ga4clickLabel}`, 'background: #222; color: #ffff00');
        }


        /* eslint-disable */
        if (window.utag) {
            if (params.action) {
                console.info(`%c tealiumEvent -> Category: ${eventCategory} | Action: ${params.action} | Label: ${params.label}`, 'background: #263e6a; color: #ffffff');
                window.utag.link({
                    'event_category': eventCategory,
                    'event_action': params.action,
                    'event_label': params.label
                });
            }

            if (params.ga4clickLabel) {
                console.info(`%c GA4 -> tealiumEvent -> eventName: click | clickLabel: ${params.ga4clickLabel}`, 'background: #263e6a; color: #ffffff');
                window.utag.link({
                    'event_name': 'click',
                    'click_label': params.ga4clickLabel,
                    'event_language': 'de'
                });
            }
            if (params.ga4eventName === 'form-error') {
                console.info(`%c GA4 -> tealiumEvent -> eventName: ${params.ga4eventName} | formName: ${params.ga4formName} | MSG: ${params.ga4errorDisplayedMessage}`, 'background: #263e6a; color: #ffffff');
                window.utag.link({
                    'event_name': params.ga4eventName,
                    'form_name': this.ga4formatString(params.ga4formName),
                    'error_displayed_message': this.ga4formatString(params.ga4errorDisplayedMessage),
                    'event_language': 'de'
                });
            }
            if (params.ga4eventName === 'insurance') {
                console.info(`%c GA4 -> tealiumEvent -> eventName: ${params.ga4eventName} |  ${params.ga4insuranceSpecification} | ${params.ga4insurancePrice}`, 'background: #263e6a; color: #ffffff');
                window.utag.link({
                    'event_name': params.ga4eventName,
                    'insurance_specification': params.ga4insuranceSpecification,
                    'insurance_price': params.ga4insurancePrice,
                    'event_language': 'de'
                });
            }
            if (params.ga4eventName === 'error') {
                console.info(`%c GA4 -> tealiumEvent -> eventName: ${params.ga4eventName} | ${params.ga4eventType} | ${params.ga4eventRequest} | ${params.ga4eventDisplayedMessage}`, 'background: #263e6a; color: #ffffff');
                window.utag.link({
                    'event_name': params.ga4eventName,
                    'error_type': params.ga4eventType,
                    'error_request': params.ga4eventRequest,
                    'error_displayed_message': params.ga4eventDisplayedMessage,
                    'event_language': 'de'
                });
            }
            if (params.ga4eventName === 'download') {
                console.info(`%c GA4 -> tealiumEvent -> eventName: ${params.ga4eventName} | ${this.ga4formatString(params.ga4eventDownload)}`, 'background: #263e6a; color: #ffffff');
                window.utag.link({
                    'event_name': params.ga4eventName,
                    'download_specification': this.ga4formatString(params.ga4eventDownload),
                    'event_language': 'de'
                });
            }
        }
        /* eslint-enable */
    }

    /**
     * Detail components Page View - Cabin
     * @param {*} pageTitle
     */
    tealiumTrackingCabin (pageTitle) {
        const trackParamter = this.trackParamter();

        return {
            'booking_departure_country': trackParamter.itineraryStart.countryName,
            'booking_departure_port': trackParamter.itineraryStart.portName,
            'booking_destination_country': trackParamter.itineraryEnd.countryName,
            'booking_destination_port': trackParamter.itineraryEnd.portName,
            'booking_epackage_code': trackParamter.ePackageCode,
            'booking_region': this.mainRegions(),
            'booking_region_code': this.mainRegionsCode(),
            'booking_region_normalized': trackParamter.customvar6,
            'booking_ship_code': trackParamter.shipCode,
            'booking_ship_name': trackParamter.ship,
            'booking_trip_code': trackParamter.tripCode,
            'booking_trip_date_end': trackParamter.tripDateTo,
            'booking_trip_date_start': trackParamter.tripDateFrom,
            'booking_trip_duration': String(trackParamter.tripDateDuration),
            'booking_trip_name': trackParamter.tripName,
            'is_b2b': this.tealiumDataLayerIsB2B(),
            'ab_variant': trackParamter.dimension18,
            'in_native_app': trackParamter.dimension20,
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'nav_level1': pageTitle, // Required
            'page_type': 'checkout_cabin', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|kabine',
            ...this.ga4DefaultParameter()
        };
    }

    /**
     * Detail components Page View - Transport
     * @param {*} pageTitle
     */
    tealiumTrackingTransport (pageTitle) {
        const trackParamter = this.trackParamter();

        return {
            'booking_amount_cabins': String(trackParamter.cabinCount),
            'booking_cabin_deck': trackParamter.deckNumberAndName,
            'booking_price_category': trackParamter.cabinCategoryName,
            'booking_cabin_type': trackParamter.cabinCategoryTypeName,
            'booking_price_model': trackParamter.cabinPriceModel,
            'booking_price_models_number': trackParamter.availablePriceModels,
            'passenger_adult_number': String(trackParamter.adultCount),
            'passenger_children_number': String(trackParamter.childCount),
            'is_b2b': this.tealiumDataLayerIsB2B(),
            'ab_variant': trackParamter.dimension18,
            'in_native_app': trackParamter.dimension20,
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'nav_level1': pageTitle, // Required
            'page_type': 'checkout_travel', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|an-abreise',
            ...this.ga4DefaultParameter()
        };
    }

    /**
     * Detail components Page View - Personal
     * @param {*} pageTitle
     */
    tealiumTrackingPersonal (pageTitle) {
        const trackParamter = this.trackParamter();

        return {
            'booking_departure_city': (trackParamter.shipBoundTransport === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) ? trackParamter.shipBoundOrigin : '',
            'booking_destination_city': (trackParamter.homeBoundTransport === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) ? trackParamter.homeBoundDestination : '',
            'booking_transport_type': this.tealiumDataLayerTransport(),
            'is_b2b': this.tealiumDataLayerIsB2B(),
            'ab_variant': trackParamter.dimension18,
            'in_native_app': trackParamter.dimension20,
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'nav_level1': pageTitle, // Required
            'page_type': 'checkout_personaldata', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|persoenliche-daten',
            ...this.ga4DefaultParameter()
        };
    }

    /**
     * Detail components Page View - Insurance
     * @param {*} pageTitle
     */
    tealiumTrackingInsurance (pageTitle) {
        const trackParamter = this.trackParamter();

        return {
            'is_b2b': this.tealiumDataLayerIsB2B(),
            'ab_variant': trackParamter.dimension18,
            'in_native_app': trackParamter.dimension20,
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'nav_level1': pageTitle, // Required
            'page_type': 'checkout_insurance', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|reiseversicherung',
            ...this.ga4DefaultParameter()
        };
    }

    /**
     * Detail components Page View - Overview
     * @param {*} pageTitle
     */
    tealiumTrackingOverview (pageTitle) {
        const insuranceData = this.tealiumDataLayerInsurance();
        const priceData = this.tealiumDataLayerPrice();
        const trackParamter = this.trackParamter();

        return {
            'insurance_details': insuranceData.details,
            'insurance_type': insuranceData.type,
            'insurance_price_total': String(priceData.insurance),
            'is_b2b': this.tealiumDataLayerIsB2B(),
            'ab_variant': trackParamter.dimension18,
            'in_native_app': trackParamter.dimension20,
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'nav_level1': pageTitle, // Required
            'page_type': 'checkout_overview', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|uebersicht',
            ...this.ga4DefaultParameter()
        };
    }

    /**
     * Detail components Page View - Payment
     * @param {*} pageTitle
     */
    tealiumTrackingPayment (pageTitle) {
        const trackParamter = this.trackParamter();

        return {
            'is_b2b': this.tealiumDataLayerIsB2B(),
            'ab_variant': trackParamter.dimension18,
            'in_native_app': trackParamter.dimension20,
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'nav_level1': pageTitle, // Required
            'page_type': 'checkout_payment', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|zahlung',
            ...this.ga4DefaultParameter()

        };
    }

    /**
     * Detail components Page View - Confirm
     * @param {*} pageTitle
     */
    tealiumTrackingConfirm (pageTitle, values) {
        const trackParamter = this.trackParamter();
        const insuranceData = this.tealiumDataLayerInsurance();
        const priceData = this.tealiumDataLayerPrice();

        return {
            'booking_amount_cabins': String(trackParamter.cabinCount),
            'booking_cabin_deck': trackParamter.deckNumberAndName,
            'booking_cabin_type': trackParamter.cabinCategoryTypeName,
            'booking_departure_city': (trackParamter.shipBoundTransport === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) ? trackParamter.shipBoundOrigin : '',
            'booking_departure_country': trackParamter.itineraryStart.countryName,
            'booking_departure_port': trackParamter.itineraryStart.portName,
            'booking_destination_city': (trackParamter.homeBoundTransport === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) ? trackParamter.homeBoundDestination : '',
            'booking_destination_country': trackParamter.itineraryEnd.countryName,
            'booking_destination_port': trackParamter.itineraryEnd.portName,
            'booking_epackage_code': trackParamter.ePackageCode,
            'booking_number': String(values.id),
            'booking_price_total': String(values.revenue),
            'booking_price_category': trackParamter.cabinCategoryName,
            'booking_price_discount_total': String(priceData.discounts),
            'booking_price_model': trackParamter.cabinPriceModel,
            'booking_price_models_number': trackParamter.availablePriceModels,
            'booking_region': this.mainRegions(),
            'booking_region_code': this.mainRegionsCode(),
            'booking_region_normalized': trackParamter.customvar6,
            'booking_ship_code': trackParamter.shipCode,
            'booking_ship_name': trackParamter.ship,
            'booking_transport_type': this.tealiumDataLayerTransport(),
            'booking_trip_code': trackParamter.tripCode,
            'booking_trip_date_end': trackParamter.tripDateTo,
            'booking_trip_date_start': trackParamter.tripDateFrom,
            'booking_trip_duration': String(trackParamter.tripDateDuration),
            'booking_trip_name': trackParamter.tripName,
            'passenger_adult_number': String(trackParamter.adultCount),
            'passenger_children_number': String(trackParamter.childCount),
            'insurance_details': insuranceData.details,
            'insurance_type': insuranceData.type,
            'insurance_price_total': String(priceData.insurance),
            'is_b2b': this.tealiumDataLayerIsB2B(),
            'ab_variant': trackParamter.dimension18,
            'in_native_app': trackParamter.dimension20,
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'nav_level1': pageTitle, // Required
            'page_type': 'checkout_confirmation', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|buchungsbestaetigung',
            ...this.ga4DefaultParameter()
        };
    }

    /**
     * Detail components Page View - Error
     * @param {*} values
     */
    tealiumTrackingError (values = {}) {
        let trackParamter = null;
        if (app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER)) {
            trackParamter = this.trackParamter();
        }
        let tealiumError;
        if (!values.action) {
            // translate if necessary
            tealiumError = this.tealiumEventErrorParams(values.error);
            values.action = tealiumError.action;
        }

        let pageTitle = '';
        if (values.path && values.path.replace('/', '')) {
            const pagePages = app.bookingPages.get(values.path.replace('/', '')) || null;
            const pageObject = (pagePages) ? pagePages.get('tracking') : {};
            pageTitle = pageObject.pageTitle || values.path;
        }

        return {
            'error_action': values.action,
            'error_code': values.error,
            'error_trip': values.label,
            'is_b2b': trackParamter ? this.tealiumDataLayerIsB2B() : '',
            'ab_variant': trackParamter ? trackParamter.dimension18 : '',
            'in_native_app': trackParamter ? trackParamter.dimension20 : '',
            'utm_campaign': trackParamter.utmCampaign,
            'utm_content': trackParamter.utmContent,
            'utm_id': trackParamter.utmId,
            'utm_medium': trackParamter.utmMedium,
            'utm_source': trackParamter.utmSource,
            'utm_term': trackParamter.utmTerm,
            'error_path': pageTitle,
            'page_type': 'checkout_error', // Required
            // GA4
            'page_name': 'www_meinschiff_com|ibe|ihre-reise-ist-im-urlaub',
            ...this.ga4DefaultParameter()
        };
    }

    /**
     * Data Layer Transport helper
     */
    tealiumDataLayerTransport () {
        const trackParamter = this.trackParamter();
        let result = '';

        switch (trackParamter.shipBoundTransport) {
            case Constants.NETMATCH_TRANSPORT_OPTION_TRAIN:
                result = 'Bahn';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_BUS:
                result = 'Bus';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT:
                result = 'Flugzeug';
                break;
            default:
                result = 'Eigene Anreise';
                break;
        }
        if (trackParamter.shipBoundTransport !== trackParamter.homeBoundTransport) {
            switch (trackParamter.homeBoundTransport) {
                case Constants.NETMATCH_TRANSPORT_OPTION_TRAIN:
                    result = `${result},Bahn`;
                    break;
                case Constants.NETMATCH_TRANSPORT_OPTION_BUS:
                    result = `${result},Bus`;
                    break;
                case Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT:
                    result = `${result},Flugzeug`;
                    break;
                default:
                    result = `${result},Eigene Abreise`;
                    break;
            }
        } else {
            if (trackParamter.shipBoundTransport === Constants.NETMATCH_TRANSPORT_OPTION_OWN) {
                result = 'Eigene An- und Abreise';
            }
        }
        return result;
    }

    /**
     * Data Layer Insurance helper
     */
    tealiumDataLayerInsurance () {
        const trackParamter = this.trackParamter();
        const insurancesData = trackParamter.insurances;
        const result = {
            'details': '',
            'type': ''
        };
        let insuranceDetails, insuranceType;

        insuranceDetails = insuranceType = '';
        insurancesData.forEach(item => {
            insuranceDetails = `${insuranceDetails}${item.memberType},`;
            insuranceType = `${insuranceType}${item.insurance},`;
        });
        result.details = insuranceDetails.slice(0, -1);
        result.type = insuranceType.slice(0, -1);

        return result;
    }

    /**
     * Data Layer Price helper
     */
    tealiumDataLayerPrice () {
        const trackParamter = this.trackParamter();
        const paymentData = trackParamter.participantPrices;
        const result = {
            'discounts': 0,
            'insurance': 0
        };

        paymentData.forEach(item => {
            item.prices.forEach(itemPrice => {
                if (itemPrice.serviceCategory === 'Insurance') {
                    result.insurance = result.insurance + itemPrice.price;
                }
                if (itemPrice.discounts) {
                    itemPrice.discounts.forEach(itemDiscount => {
                        result.discounts = result.discounts + itemDiscount.discount;
                    });
                }
            });
        });
        return result;
    }

    /**
     * Data Layer B2B helper
     */
    tealiumDataLayerIsB2B () {
        const trackParamter = this.trackParamter();
        return trackParamter.isB2B.toString();
    }

    /**
     * Event Label
     * @param {*} values
     * @param {*} pageView
     */
    tealiumEventLabel (values, pageView) {
        let result = '';
        switch (values.key) {
            /* case Constants.PAGE_CABIN:
                result += this.tealiumEventLabelCabin();
                break; */
            case Constants.PAGE_TRAVEL:
                result += this.tealiumEventLabelTransport();
                break;
            case Constants.PAGE_INSURANCE:
                result += this.tealiumEventLabelInsurance(pageView);
                break;
            case Constants.PAGE_OVERVIEW:
                result += this.tealiumEventLabelOverview();
                break;
            case Constants.PAGE_PAYMENT:
                result += this.tealiumEventLabelPayment();
                break;
            default:
                break;
        }

        if (values.key !== Constants.PAGE_OVERVIEW) {
            result += `${values.text}`;
        }
        if (values.key !== Constants.PAGE_CABIN) {
            if (values.position) {
                if (values.position === 'bottom') {
                    result += '_unten';
                }
                if (values.position === 'sticky') {
                    result += '_sticky';
                }
            }
        }
        return result;
    }

    /**
     * Detail components Event Label - Cabin button click
     */
    tealiumEventLabelCabin () {
        const trackParamter = this.trackParamter();
        // Reisetermin2020-01-17-2020-01-31|Erwachsene9|Kinder3|Kabinen4|Weiter zur An- & Abreise_sticky
        let result = `Reisetermin${trackParamter.tripDateFrom}-${trackParamter.tripDateTo}|`;
        result += `Erwachsene${trackParamter.adultCount}|`;
        result += `Kinder${trackParamter.childCount}|`;
        result += `Kabinen${trackParamter.cabinCount}|`;
        return result;
    }

    /**
     * Detail components Event Label - Transport button click
     */
    tealiumEventLabelTransport () {
        const trackParamter = this.trackParamter();
        let result = '';
        // Bahn|Weiter zur Versicherung
        // Bus|Weiter zur Versicherung
        // Hinflug|Rückflug|Weiter zur Versicherung
        // Eigene An- und Abreise|Weiter zur Versicherung
        switch (trackParamter.shipBoundTransport.toLowerCase()) {
            case Constants.NETMATCH_TRANSPORT_OPTION_TRAIN:
                result += 'Bahn|';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_BUS:
                result += 'Bus|';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT:
                result += 'Hinflug|';
                break;
            default:
                result += 'Eigene Anreise|';
                break;
        }
        switch (trackParamter.homeBoundTransport.toLowerCase()) {
            case Constants.NETMATCH_TRANSPORT_OPTION_TRAIN:
                result += 'Bahn|';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_BUS:
                result += 'Bus|';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT:
                result += 'Rückflug|';
                break;
            default:
                result += 'Eigene Abreise|';
                break;
        }
        return result;
    }

    /**
     * Detail components Event Label - Insurance button click
     * @param {*} pageView
     */
    tealiumEventLabelInsurance (pageView) {
        // Beispiel 1: TUI Cruises Premiumschutz|Paar|Ohne Selbstbeteiligung|Weiter zu Ihren Daten
        // Beispiel 2: keine Versicherung|Weiter zu Ihren Daten
        // Beispiel 3: Unterschiedliche Versicherungen|Weiter zu Ihren Daten

        const insuranceSelectorModel = pageView.insuranceSelectorModel;

        // Beispiel 2: keine Versicherung|Weiter zu Ihren Daten
        if (insuranceSelectorModel.insurances.every(insurance => insurance.isDummyInsurance())) {
            return 'keine Versicherung|';
        }

        // Beispiel 3: Unterschiedliche Versicherungen|Weiter zu Ihren Daten
        const noneDummyInsuranceCount = insuranceSelectorModel.insurances.reduce((count, insurance) => {
            if (insurance.isDummyInsurance()) {
                return count;
            }
            return count + 1;
        }, 0);
        if (noneDummyInsuranceCount > 1) {
            return 'Unterschiedliche Versicherungen|';
        }

        // Beispiel 1: TUI Cruises Premiumschutz|Paar|Ohne Selbstbeteiligung|Weiter zu Ihren Daten
        const selectedInsurance = insuranceSelectorModel.insurances.find(insurance => !insurance.isDummyInsurance());
        let insuranceType = 'Einzel';
        if (selectedInsurance.isCoupleInsurance()) {
            insuranceType = 'Paar';
        } else if (selectedInsurance.isFamilyInsurance()) {
            insuranceType = 'Familie';
        }

        const policyTypeStep = selectedInsurance.policyTypeStep;
        const policyOption = policyTypeStep.inputs.get(Constants.NETMATCH_INPUT_POLICY).get('options').find(option => option.selected);

        const retention = policyOption.withRetention ? 'Mit Selbstbeteiligung' : 'Ohne Selbstbeteiligung';
        const coronaCoverage = policyOption.includesCoronaCoverage ? ' + Corona Zusatzschutz' : '';

        return `${policyOption.displayText}|${insuranceType}|${retention}${coronaCoverage}|`;
    }

    /**
     * Detail components Event Label - Overview button click
     */
    tealiumEventLabelOverview () {
        const trackParamter = this.trackParamter();
        // Buchen|Mastercard
        return `Buchen|${trackParamter.paymentTypeView}`;
    }

    /**
     * Detail components Event Label - Payment button click
     */
    tealiumEventLabelPayment () {
        // Überweisung|Buchung abschließen
        // SEPA|Buchung abschließen
        // KreditkarteBuchung abschließen
        const trackParamter = this.trackParamter();
        let result = '';
        if (trackParamter.paymentTypeApi === Constants.NETMATCH_STEP_PAYMENT_API_RESULT_SEPA) {
            result = 'SEPA|';
        } else if (trackParamter.paymentTypeApi === Constants.NETMATCH_STEP_PAYMENT_API_RESULT_BANK_TRANFER) {
            result = 'Überweisung|';
        } else if (trackParamter.paymentTypeApi === Constants.NETMATCH_STEP_PAYMENT_API_RESULT_CREDITCARD) {
            result = 'Kreditkarte|';
        }

        return result;
    }

    /**
     * Detail components Event 'category' and 'action' - Error
     * @param {*} type
     */
    tealiumEventErrorParams (type) {
        const value = {
            category: 'Fehler',
            action: 'IBE - Other'
        };
        const initParams = app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER);
        const httpStatus = app.ibeController.ibeStatus.httpStatus;
        if (type === '503-data' && httpStatus !== '200') {
            type = '503-ibe';
        }
        switch (type) {
            case '503-ibe':
                value.action = 'IBE - IBEAPI';
                if (!initParams) {
                    value.action = 'IBE - NO INIT PARAMETER';
                }
                break;
            case '503-data':
                value.action = 'IBE - DataMissmatch';
                if (!initParams) {
                    value.action = 'IBE - NO INIT PARAMETER';
                }
                break;
            case '503-content':
                value.action = 'IBE - ContentMiddleware';
                break;
            case '503-other':
                value.action = 'IBE - Other';
                break;
            case '404-url':
                value.action = 'IBE - URL nicht gefunden';
                break;
            case '503-restoreBooking':
                value.action = 'IBE - cartRecovery';
                break;
            case 'deckplan-error':
                value.action = 'IBE - CM - DeckInformation';
                break;
        }
        return value;
    }

    /**
     * GA4 Ecommerce Tracking
     * TUICUNIT-3138
     * @param {*}
     */
    ga4TealiumEcommerceGeneral () {
        const trackParamter = this.trackParamter();
        const insuranceData = this.tealiumDataLayerInsurance();
        const priceData = this.tealiumDataLayerPrice();
        const initTackingParams = app.ibeController.ibeStorage.get(Constants.TRACKING_PARAMETER) || {};

        let ecArrival = '';
        switch (trackParamter.shipBoundTransport) {
            case Constants.NETMATCH_TRANSPORT_OPTION_TRAIN:
                ecArrival = 'Bahn';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_BUS:
                ecArrival = 'Bus';
                break;
            case Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT:
                ecArrival = 'Flugzeug';
                break;
            default:
                ecArrival = 'Eigene Anreise';
                break;
        }

        return {
            'currency': 'EUR',
            'value': trackParamter.totalPrice,
            'price': trackParamter.totalPrice,
            'ec_coupon': trackParamter.ecCampainCodes,
            'ec_agency_code': (trackParamter.isB2B) ? (trackParamter.agencyNumber) : '',
            'ec_code': trackParamter.tripCode,
            'ec_name': this.ga4formatString(trackParamter.tripName),
            'ec_ship': this.ga4formatString(trackParamter.ship),
            'ec_discount': this.ga4FormatPrice(priceData.discounts),
            'ec_list_position': this.ga4formatString(initTackingParams.ecListPosition || ''),
            'ec_list_code': this.ga4formatString(initTackingParams.ecListCode || ''),
            'ec_list_name': this.ga4formatString(initTackingParams.ecListName || ''),
            'ec_list_page': this.ga4formatString(initTackingParams.ecListPage || ''),
            'ec_ship_brand': trackParamter.ecShipBrand,
            'item_category': this.ga4formatString(initTackingParams.itemCategory || ''),
            'ec_passengers': trackParamter.ecPassengers,
            'ec_nights': `${trackParamter.tripDateDuration}`,
            'ec_cabins': trackParamter.cabinCount,
            'ec_cabin_amount': trackParamter.cabinCount,
            'ec_cabin_type': this.ga4formatMultiCabin(trackParamter.cabinCount, trackParamter.cabinCategoryTypeName),
            'ec_cabin_number': this.ga4formatMultiCabin(trackParamter.cabinCount, trackParamter.ecCabinNumber),
            'ec_cabin_deck': this.ga4formatMultiCabin(trackParamter.cabinCount, trackParamter.ecCabinDeck),
            'ec_cabin_tariff': this.ga4formatMultiCabin(trackParamter.cabinCount, trackParamter.cabinPriceModel),
            'ec_cabin_price': this.ga4formatMultiCabin(trackParamter.cabinCount, trackParamter.ecCabinPrice),
            'ec_insurance_specification': this.ga4formatString(insuranceData.type),
            'ec_insurance_price': this.ga4FormatPrice(priceData.insurance),
            'ec_arrival': this.ga4formatString(ecArrival),
            'item_variant': trackParamter.itemVariant,
            'ec_start_date': trackParamter.ecStartDate,
            'ec_end_date': trackParamter.ecEndDate,
            'ec_region': this.ga4formatString(this.mainRegions()),
            'ec_quantity': 1,
            ...this.ga4DefaultParameter()
        };
    }

    ga4TealiumEcommerceBegin () {
        return {
            'event_name': 'begin_checkout',
            'page_name': 'www_meinschiff_com|ibe|reiseversicherung',
            ...this.ga4TealiumEcommerceGeneral()
        };
    }

    ga4TealiumEcommerceView () {
        // warum auch immer
        const insuranceData = this.tealiumDataLayerInsurance();
        const priceData = this.tealiumDataLayerPrice();
        this.eventTracking({
            ga4eventName: 'insurance',
            ga4insuranceSpecification: this.ga4formatString(insuranceData.type),
            ga4insurancePrice: this.ga4FormatPrice(priceData.insurance)
        });

        return {
            'event_name': 'view_cart',
            'page_name': 'www_meinschiff_com|ibe|uebersicht',
            ...this.ga4TealiumEcommerceGeneral()
        };
    }

    ga4TealiumEcommerceAddPaymentInfo () {
        const trackParamter = this.trackParamter();
        return {
            'event_name': 'add_payment_info',
            'ec_payment_method': this.ga4formatString(trackParamter.paymentTypeView),
            'page_name': 'www_meinschiff_com|ibe|uebersicht',
            ...this.ga4TealiumEcommerceGeneral()
        };
    }

    ga4TealiumEcommerceAddToCart () {

        return {
            'event_name': 'add_to_cart',
            'page_name': 'www_meinschiff_com|ibe|kabine',
            'ec_list_page': '1',
            ...this.ga4TealiumEcommerceGeneral()
        };
    }

    ga4TealiumEcommercePurchase (values) {
        const trackParamter = this.trackParamter();
        return {
            'event_name': 'purchase',
            'booking_number': this.ga4formatString(values.id),
            'booking_price_total': this.ga4FormatPrice(values.revenue),
            'ec_payment_method': this.ga4formatString(trackParamter.paymentTypeView),
            'booking_tax': '',
            'booking_shipping': '',
            'page_name': 'www_meinschiff_com|ibe|buchungsbestaetigung',
            ...this.ga4TealiumEcommerceGeneral()
        };
    }

    getLocale () {

        const url = new Url();
        const queryParameters = url.queryParameters;

        const localLocale = window.localStorage.getItem('appLocale');

        const targetLocale = this.extractLocale(queryParameters.locale || localLocale || window.navigator.language);

        return targetLocale;
    }

    extractLocale (locale) {
        if (locale.startsWith('de')) {
            return 'de';
        }

        return 'en';
    }

}

export default TrackController;
