import { getLocaleAPI } from '../intl';
import { isDev } from './config';

const pclRootUrl = isDev ? 'https://api.tuicruises.com/pcl-test' : 'https://api.tuicruises.com/pcl';

const rootURL = '/api/content';

const ibeTeaserURL = `${rootURL}/ibeTeaser`;

const airportsURL = `${rootURL}/airports`;

const airlinesURL = `${rootURL}/airlines`;

const messagesURL = `${rootURL}/messages`;

const routeImageByTripCodeURL = `${rootURL}/routeImage?tripCode={tripCode}`;

const callcenterEmployeeURL = `${rootURL}/callcenterEmployee`;

const cabinTypesAndCategoriesByeTripCodeURL = `${rootURL}/cabinTypesAndCategories?tripCode={tripCode}`;

const deckInformationByeTripCodeAndDeckURL = `${rootURL}/deckInformation?tripCode={tripCode}&deck={deck}`;

const tripInfoURL = `${rootURL}/tripInfo?tripCode={tripCode}`;

const mediaProxyURL = '/api/content-media';

const i18nUrlAirlines = (locale = getLocaleAPI()) => `${pclRootUrl}/airlines?locale=${locale}`;
const i18nUrlAirports = (locale = getLocaleAPI()) => `${pclRootUrl}/airports?locale=${locale}`;
const i18nUrlTripInfo = (tripCode, locale = getLocaleAPI()) => `${pclRootUrl}/trips?locale=${locale}&tripCodes=${tripCode}`;
const i18nUrlCabinTypesAndCategories = (tripCode, locale = getLocaleAPI()) => `${pclRootUrl}/trips/${tripCode}/cabinTypesAndCategories?locale=${locale}`;
const i18nUrlMessages = (locale = getLocaleAPI()) => `${rootURL}/messages?locale=${locale}`;
const i18nUrlDeckInfo = (tripCode, deck, locale = getLocaleAPI()) => `${rootURL}/deckInformation?tripCode=${tripCode}&deck=${deck}&locale=${locale}`;

export default {
    rootURL,

    ibeTeaserURL,

    airportsURL,

    airlinesURL,

    messagesURL,

    routeImageByTripCodeURL,

    callcenterEmployeeURL,

    cabinTypesAndCategoriesByeTripCodeURL,

    deckInformationByeTripCodeAndDeckURL,

    tripInfoURL,

    mediaProxyURL,

    // i18n
    i18nUrlAirlines,
    i18nUrlAirports,
    i18nUrlTripInfo,
    i18nUrlCabinTypesAndCategories,
    i18nUrlMessages,
    i18nUrlDeckInfo
};
