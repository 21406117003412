import BaseModel from '../../../../base/model/base';
import TripModel from './trip';
import PartyModel from './party';
import ParticipantPricesCollection from './participantPrices';
import AgencyModel from './agency';
import BookingCodeModel from './bookingCode';
import TransportModel from './transport';
import InsurancesCollection from './insurances';
import CampaignCodesCollection from './campaignCode';
import * as Constants from '../../../../config/constants';


export default class BookingStatusModel extends BaseModel {
    /**
     *
     */
    constructor (...args) {
        super(...args);

        const triggerChange = () => {
            this.trigger('change');
        };

        this.trip = new TripModel(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.trip, 'change', triggerChange);

        this.party = new PartyModel(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.party, 'change', triggerChange);

        this.participantPrices = new ParticipantPricesCollection(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.participantPrices, 'change', triggerChange);

        this.agency = new AgencyModel(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.agency, 'change', triggerChange);

        this.bookingCode = new BookingCodeModel(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.bookingCode, 'change', triggerChange);

        this.shipBoundTransport = new TransportModel(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.shipBoundTransport, 'change', triggerChange);

        this.homeBoundTransport = new TransportModel(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.homeBoundTransport, 'change', triggerChange);

        this.insurances = new InsurancesCollection(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.insurances, 'change', triggerChange);

        this.campaignCodes = new CampaignCodesCollection(null, {
            bookingStatusModel: this
        });
        this.listenTo(this.campaignCodes, 'change', triggerChange);
    }


    idAttribute () {
        return 'id';
    }


    toJSON () {
        // console.log('toJSON => ', this);
        const data = {
            ...super.toJSON.apply(this),
            trip: this.trip.toJSON(),
            party: this.party.toJSON(),
            participantPrices: this.participantPrices.toJSON(),
            agency: this.agency.toJSON(),
            bookingCode: this.bookingCode.toJSON(),
            shipBoundTransport: this.shipBoundTransport.toJSON(),
            homeBoundTransport: this.homeBoundTransport.toJSON(),
            insurances: this.insurances.toJSON(),
            campaignCodes: this.campaignCodes.toJSON()
        };
        return data;
    }


    getTripCode () {
        return this.trip.get('auftragsnummer');
    }

    getCabinData () {
        return this.get('cabin');
    }

    /**
     * @returns {string} mix | flex | feel | feelP | pro | plus | pur
     */
    getPriceModel () {
        let result = 'mix';
        const cabin = this.get('cabin') || null;

        if (cabin && cabin.length === 1 && cabin[0].priceModel && cabin[0].priceModel !== null) {
            result = 'mix';
            switch (cabin[0].priceModel) {
                case Constants.NETMATCH_SESSION_CABIN_PRICEMODEL_FLEX:
                    result = 'flex';
                    break;
                case Constants.NETMATCH_SESSION_CABIN_PRICEMODEL_FEELGOOD:
                    result = 'feel';
                    break;
                case Constants.NETMATCH_SESSION_CABIN_PRICEMODEL_FEELGOODPLUS:
                    result = 'feelP';
                    break;
                case Constants.NETMATCH_STATUS_CABIN_PRICEMODEL_PRO:
                    result = 'pro';
                    break;
                case Constants.NETMATCH_STATUS_CABIN_PRICEMODEL_PLUS:
                    result = 'plus';
                    break;
                case Constants.NETMATCH_STATUS_CABIN_PRICEMODEL_PUR:
                    result = 'pur';
                    break;
                default:
                    result = 'mix';
                    break;
            }
        }
        return result;
    }

    /**
     * Overwritten version of the default model set method, that utilizes the parse method
     */
    set (key, val, options) {
        let attrs;
        if (typeof key === 'object') {
            attrs = key;
            options = val;
        } else {
            attrs = {};
            attrs[key] = val;
        }
        options = options || {};

        if (options.parse) {
            attrs = this.parse(attrs, options);
        }

        super.set.apply(this, [attrs, options]);
    }


    /**
     * Converts date strings from the API into JS Date objects
     *
     * @param  {object} response
     * @return {object}
     */
    parse (response) {
        // console.log('= = = = = = = = > BookingStatusModel: parse', response);
        this.trip.set(response.trip);
        delete response.trip;

        this.party.set(response.party);
        delete response.party;

        this.participantPrices.set(response.participantPrices);
        delete response.participantPrices;

        this.agency.set(response.agency);
        delete response.agency;

        this.bookingCode.set(response.bookingCodes);
        delete response.bookingCodes;

        this.shipBoundTransport.set({
            direction: Constants.SHIPBOUND,
            ...response.shipBoundTransport
        });
        delete response.shipBoundTransport;

        this.homeBoundTransport.set({
            direction: Constants.HOMEBOUND,
            ...response.homeBoundTransport
        });
        delete response.homeBoundTransport;

        this.insurances.set(response.insurances);
        delete response.insurances;

        this.campaignCodes.set(response.campaignCodes || []);
        delete response.campaignCodes;

        // if (response.startDate) {
        //     response.startDate = fecha.parse(response.startDate, 'edmDateTimeOffset');
        // }

        // if (response.endDate) {
        //     response.endDate = fecha.parse(response.endDate, 'edmDateTimeOffset');
        // }
        // console.log('= = = = = = = = > BookingStatusModel: parse', response);

        return response;
    }
}
